@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

@font-face {
  font-family: 'Ustroke Regular';
  src: url("./fonts/19846.otf");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Engry Font';
  src: url("./fonts/engry.otf");
  font-style: normal;
  font-weight: normal;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.app {
  min-height: 100vh;
  background-color: #30404d;
  display: flex;
  flex-direction: column;
  color: #fbfff0bd;
  align-items: center;
  background-image: url('./assets/chassis.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.content-wrapper {
  width: 840px;
}

.google-map {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 480px;
}

h3 {
  color: #c8e528;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}

p {
  color: bisque;
  text-align: left;
}

.info-block {
  flex-direction: column;
  /*align-items: baseline;*/
  background-color: #2e322ee0;
  color: whitesmoke;
  text-align: center;
  width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: Arial, serif;
}

.header {
  font-size: 20px;
  font-family: 'Engry Font', serif;
}
@media screen and (max-width: 840px) {
  .app {
    background-image: url('assets/chassis.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content-wrapper {
    width: 100%
  }

  .info-block {
    height: auto;
    padding: 5px;
    font-size: 16px;
    width: 100%
  }

  .header {
    font-size: 16px;
  }
}

@media screen and (max-width: 414px) {
  .content-wrapper {
    width: 100%
  }

  .bp3-header {
    font-size: 12px;
  }

  .info-block {
    height: auto;
    padding: 5px;
    font-size: 12px;
  }

  .contacts {
    align-items: flex-start;
  }
}